<svelte:options tag="cleandesk-portfolio-listing" />

<script>
  export let listType = "product";
  import { onMount } from "svelte";
  import InfiniteScroll from "../../common/InfiniteScroll.svelte";
  import SearchMain from "../../common/components/SearchMain.svelte";
  import { CATALOG_MODULE } from "../../config/api-variables";
  import { getAuthKey, getPersonOrgOfficeId } from "../../utils/cookie/user";
  import PortfolioCard from "./components/PortfolioCard.svelte";
  import axios from "axios";
  import LoadingOnScroll from "../../common/components/LoadingOnScroll.svelte";

  let newBatch = [];
  let page = 1;
  let searchQuery = "";
  let portfolioList = [];
  let productListLoading = false;
  let moreLoading = false;

  let endCursor = null;

  const headers = { Authorization: "Token " + getAuthKey() };

  const searchList = (searchValue) => {
    page = 1;
    portfolioList = [];
    newBatch = [];
    searchQuery = searchValue;
    endCursor = null;
    fetchData(10, searchValue);
  };

  const fetchData = (page_size = 10, search_val) => {
    productListLoading = true;
    const payload = {
      organisation_office_id: parseInt(getPersonOrgOfficeId()),
      search_val,
      page_size: page_size,
      page_number: page,
      status: "active",
    };
    axios
      .post(CATALOG_MODULE + "/portfolio/list", { ...payload }, { headers })
      .then((response) => {
        productListLoading = false;
        moreLoading = false;
        newBatch = response.data.rows;
        portfolioList = [...portfolioList, ...newBatch];

        endCursor = response.data?.data?.endCursor;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  onMount(() => {
    fetchData();
  });
</script>

<div style="height: 100%;">
  <div style="padding: 8px 12px;">
    <SearchMain
      on:search={(e) => {
        searchList(e.detail);
      }}
    />
  </div>

  <div class="chat-list" style="height: calc(100% - 53px);">
    {#if portfolioList.length > 0}
      <ul style="margin: 0;">
        {#each portfolioList as portfolio}
          <PortfolioCard {portfolio} />
        {/each}
        <InfiniteScroll
          hasMore={newBatch.length}
          threshold={100}
          on:loadMore={() => {
            page++;
            fetchData(10, searchQuery);
            moreLoading = true;
          }}
        />
        <LoadingOnScroll loading={moreLoading} />
      </ul>
    {:else if portfolioList.length === 0 && !productListLoading}
      <div style="">
        <p style="text-align: center; padding: 8px;">No Portfolio found</p>
      </div>
    {:else if productListLoading}
      <div class="chat-list-spinner">
        <h3><span class="visually-hidden">visuallyhidden</span></h3>
      </div>
    {/if}
  </div>
</div>
