import axios from "axios";
import { DOMAIN } from "../config/api-variables";
import {
  getAuthKey,
  getPersonId,
  getPersonOrgOfficeId,
  getSessionId,
  removeSessionId,
  setSessionId,
} from "./cookie/user";
import { ticketMainId, oldTicketMainId } from "../stores/chatStores";
import { get } from "svelte/store";

export const SessionCountApi = (payload) => {
  const headers = { Authorization: "Token " + getAuthKey() };

  axios
    .post(
      DOMAIN + "/api/v1/hd/session/create",
      {
        person_id: parseInt(getPersonId()),
        organisation_office_id: parseInt(getPersonOrgOfficeId()),
        ...payload,
      },
      { headers }
    )
    .then((response) => {
      if (response.data.statusCode === "S10001") {
        if (!response.data?.rows?.end_time) {
          setSessionId(response.data?.rows?.session_id);
        } else {
          removeSessionId();
        }
      }
    })
    .catch((error) => {
      // Handle the error
      console.error(error);
    });
};

export const StartSessionCountApi = () => {
  const conversationId = get(ticketMainId);

  SessionCountApi({
    start_time: new Date().getTime(),
    end_time: null,
    conversation_id: parseInt(conversationId),
  });
};

export const EndSessionCountApi = () => {
  const oldConversationId = get(oldTicketMainId);

  if (getSessionId()) {
    SessionCountApi({
      end_time: new Date().getTime(),
      session_id: parseInt(getSessionId()),
      conversation_id: parseInt(oldConversationId),
    });
  }
};
