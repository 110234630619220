<svelte:options tag="cleandesk-submenu-button" />

<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { DOMAIN } from "../../config/api-variables";
  import MenuItemWithIcon from "./MenuItemWithIcon.svelte";
  import id from "date-fns/locale/id";

  export let menuItem;
  export let expanded = false;
  // export let isSelected;
  export let selectedMenuItem;
  export let expanMenuItem = null;

  let areThereChildren = false;
  let areChildrenExpanded = false;
  let menuChildren = [];

  $: if (menuItem?.children?.length > 0) {
    areThereChildren = true;
  }

  $: if (!expanded) {
    areChildrenExpanded = false;
  }

  $: if (!!expanMenuItem && expanMenuItem?.id === menuItem?.id) {
    areChildrenExpanded = true;
  }

  const dispatch = createEventDispatcher();

  function handleClick(item) {
    if (areThereChildren) {
      if (!expanded) {
        dispatch("expandMenu", { item });
        areChildrenExpanded = !areChildrenExpanded;
      } else {
        areChildrenExpanded = !areChildrenExpanded;
      }
    } else {
      dispatch("menuItemClick", { item });
    }
  }

  function handleSubMenuClick(item) {
    dispatch("menuItemClick", { item });
  }

  $: if (menuItem?.children) {
    menuChildren = menuItem?.children?.sort(
      (a, b) => parseFloat(a.weight) - parseFloat(b.weight),
    );
  }

  function checkFileExtension(fileName) {
    const allowedExtensions = ["png", "jpeg", "jpg"];

    const fileExtension = fileName.split(".").pop().toLowerCase();

    if (allowedExtensions.includes(fileExtension)) {
      return true;
    } else {
      return false;
    }
  }

  let isNotSvgIcon = false;

  onMount(() => {
    isNotSvgIcon = checkFileExtension(menuItem?.icon);
  });
</script>

<button
  class="cleandesk-left-menu-item {selectedMenuItem?.id === menuItem?.id
    ? 'secondary-background-color'
    : ''}"
  on:click={() => handleClick(menuItem)}
  style={"margin: 0 1em 0 1.2em;"}
>
  <div class="tooltip" style={!expanded && "justify-content: center"}>
    {#if expanded}
      <div
        style="display: flex; align-items: center; justify-content: space-between; width: 100%;"
      >
        <div style="display: flex; align-items: center;">
          {#if isNotSvgIcon}
            <img src={DOMAIN + menuItem?.icon} alt="logo" width="24" />
          {:else}
            <div class="cleandesk-menu-icon-div" style="width: 24px;">
              {@html menuItem?.icon}
            </div>
          {/if}
          <span class="cleandesk-left-name">{menuItem?.title}</span>
        </div>
        {#if areThereChildren}
          <div>
            {#if !areChildrenExpanded}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
                ><path
                  d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z"
                /></svg
              >
            {:else}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
                ><path
                  d="m296-345-56-56 240-240 240 240-56 56-184-184-184 184Z"
                /></svg
              >
            {/if}
          </div>
        {/if}
      </div>
    {:else}
      {#if isNotSvgIcon}
        <img src={DOMAIN + menuItem?.icon} alt="logo" width="24" />
      {:else}
        <div class="cleandesk-menu-icon-div" style="width: 24px;">
          {@html menuItem?.icon}
        </div>
      {/if}
      <span class="tooltiptext">{menuItem?.title}</span>
    {/if}
  </div>
</button>

{#if areThereChildren && areChildrenExpanded}
  {#each menuChildren as child}
    <button
      class="cleandesk-left-menu-item {selectedMenuItem?.id === child?.id
        ? 'secondary-background-color'
        : ''}"
      on:click={() => handleSubMenuClick(child)}
      style={!expanded ? "margin: 0 0.2em;" : "margin: 0 1em 0 1.4em;"}
    >
      <div class="tooltip" style={!expanded && "justify-content: center"}>
        {#if expanded}
          <!-- <div class="cleandesk-menu-icon-div" style="width: 24px;">
            {@html child?.icon}
          </div> -->
          <MenuItemWithIcon icon={child?.icon} />
          <span class="cleandesk-left-name">{child?.title}</span>
        {:else}
          <!-- <div class="cleandesk-menu-icon-div" style="width: 24px;">
            {@html child?.icon}
          </div> -->
          <MenuItemWithIcon icon={child?.icon} />
          <span class="tooltiptext">{child?.title}</span>
        {/if}
      </div>
    </button>
  {/each}
{/if}
