<svelte:options tag="cleandesk-menu-item-with-icon" />

<script>
  import { onMount } from "svelte";
  import { DOMAIN } from "../../config/api-variables";

  export let icon;

  let isNotSvgIcon = false;

  function checkFileExtension(fileName) {
    const allowedExtensions = ["png", "jpeg", "jpg"];
    const fileExtension = fileName.split(".").pop().toLowerCase();
    return allowedExtensions.includes(fileExtension);
  }

  onMount(() => {
    isNotSvgIcon = checkFileExtension(icon);
  });
</script>

{#if isNotSvgIcon}
  <img src={DOMAIN + icon} alt="icon" width="24" />
{:else}
  <div class="cleandesk-menu-icon-div" style="width: 24px;">
    {@html icon}
  </div>
{/if}
