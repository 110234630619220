<svelte:options tag="cleandesk-portfolio-card" />

<script>
  import Carousel from "../../../common/Carousel/Carousel.svelte";
  import { capitalizeWords } from "../../../utils/tools/data-transformers";

  export let portfolio = {};

  let carouselItems = [];

  $: if (portfolio?.portfolio_photo) {
    carouselItems =
      portfolio?.portfolio_photo?.map((photo, index) => ({
        src: photo,
        alt: `Slide ${index + 1}`,
      })) || [];
  }
</script>

<div class="cleandesk-product-main-div">
  {#if carouselItems.length > 0}
    <Carousel
      items={carouselItems}
      autoplay={false}
      imageStyle="border-radius: 4px"
    />
  {/if}
  <div class="cleandesk-product-content-div">
    <div>
      {#if portfolio?.name}
        <p class="cleandesk-product-title">{portfolio?.name}</p>
      {/if}
      {#if portfolio?.description}
        <p class="cleandesk-product-description">
          {@html portfolio?.description}
        </p>
      {/if}
    </div>

    <div class="cleandesk-price-div">
      <div>
        <div>
          {#if portfolio?.industry}
            <span class="cleandesk-product-title"
              >{capitalizeWords(portfolio?.industry)}</span
            >
          {/if}
        </div>

        <div>
          {#if portfolio?.stage}
            <span class="cleandesk-price-span"
              >{capitalizeWords(portfolio?.stage)}</span
            >
          {/if}
        </div>
      </div>

      {#if portfolio?.call_to_action && portfolio?.cta_url}
        <button
          on:click={() => window.open(portfolio?.cta_url, "_blank")}
          class="cleandesk-buy-button primary-background-color custom-text-color"
          >{portfolio?.call_to_action}</button
        >
      {/if}
    </div>
  </div>
</div>

<svg style="display:none;">
  <symbol id="star" viewBox="0 0 24 24">
    <path
      d="M12 2l3.09 6.32 6.91.91-5 4.86 1.18 6.89-6.18-3.25-6.18 3.25 1.18-6.89-5-4.86 6.91-.91z"
    />
  </symbol>
  <symbol id="star-empty" viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      d="M12 17.27l-4.09 2.45 1-5.83-3.34-3.26 5.83-.85L12 5.1l2.59 5.48 5.83.85-3.34 3.26 1 5.83z"
    />
  </symbol>
</svg>
