<svelte:options tag="cleandesk-campaign-listing" />

<script>
  import { onMount } from "svelte";
  import InfiniteScroll from "../../common/InfiniteScroll.svelte";
  import SearchMain from "../../common/components/SearchMain.svelte";
  import { CAMPAIGN_MODULE } from "../../config/api-variables";
  import { getAuthKey, getPersonOrgOfficeId } from "../../utils/cookie/user";
  import axios from "axios";
  import CampaignCard from "./components/CampaignCard.svelte";
  import LoadingOnScroll from "../../common/components/LoadingOnScroll.svelte";

  let newBatch = [];
  let page = 1;
  let searchQuery = "";
  let campaignList = [];
  let campaignListLoading = false;
  let moreLoading = false;

  const headers = { Authorization: "Token " + getAuthKey() };

  const searchList = (searchValue) => {
    page = 1;
    campaignList = [];
    newBatch = [];
    searchQuery = searchValue;
    fetchData(20, searchValue);
  };

  const fetchData = (page_size = 10, search_val) => {
    campaignListLoading = true;
    const payload = {
      organisation_office_id: parseInt(getPersonOrgOfficeId()),
      search_val,
      page_size: page_size,
      page_number: page,
      status: "active",
    };
    axios
      .post(CAMPAIGN_MODULE + "/list", { ...payload }, { headers })
      .then((response) => {
        campaignListLoading = false;
        moreLoading = false;
        newBatch = response.data.rows;
        campaignList = [...campaignList, ...newBatch];
      })
      .catch((error) => {
        console.error(error);
      });
  };

  onMount(() => {
    fetchData();
  });
</script>

<div style="height: 100%;">
  <div style="padding: 8px 12px;">
    <SearchMain on:search={(e) => searchList(e.detail)} />
  </div>

  <div class="chat-list" style="height: calc(100% - 53px);">
    {#if campaignList.length > 0}
      <ul style="margin: 0;">
        {#each campaignList as campaign}
          <CampaignCard {campaign} />
        {/each}
        <InfiniteScroll
          hasMore={newBatch.length}
          threshold={100}
          on:loadMore={() => {
            page++;
            fetchData(10, searchQuery);
            moreLoading = true;
          }}
        />
        <LoadingOnScroll loading={moreLoading} />
      </ul>
    {:else if campaignList.length === 0 && !campaignListLoading}
      <div style="">
        <p style="text-align: center; padding: 8px;">No Campaign Found</p>
      </div>
    {:else if campaignListLoading}
      <div class="chat-list-spinner">
        <h3><span class="visually-hidden">visuallyhidden</span></h3>
      </div>
    {/if}
  </div>
</div>
