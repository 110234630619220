<svelte:options tag="cleandesk-mobile-bottom-menu" />

<script>
  import { createEventDispatcher } from "svelte";
  import MenuItemWithIcon from "./MenuItemWithIcon.svelte";

  export let menuItem;
  export let isSelected;

  const dispatch = createEventDispatcher();
</script>

<button
  class={isSelected ? "secondary-background-color menu-button" : "menu-button"}
  style="display: flex; flex-direction: column; align-items: center;"
  on:click={() => dispatch("menuItemClick", { item: menuItem })}
>
  <div class="cleandesk-menu-icon-div" style="width: 24px;">
    <!-- {@html menuItem?.icon} -->
    <MenuItemWithIcon icon={menuItem?.icon} />
  </div>
  <span class="tooltiptext">{menuItem?.title}</span>
</button>
