<svelte:options tag="cleandesk-chat-window" />

<script>
  import { createEventDispatcher, onMount } from "svelte";

  export let isVisible;
  export let access_by_id;

  import MessageItem from "../../components/ChatWidget/components/MessageItem.svelte";
  import MessageJson from "../../components/ChatWidget/components/MessageJson.svelte";
  import { DeviceType, userDetails, widgetType } from "../../stores/authStores";
  import {
    messages,
    messageLoading,
    disableInput,
    ticketMainId,
    callbackDisabled,
    startNewAiConversation,
    isSessionTimerActive,
    sessionTimeoutId,
    hasUserSentMessage,
    selectedConversation,
    aiMessageLoading,
    oldTicketMainId,
  } from "../../stores/chatStores";
  import { chatSocket, isSocketReset } from "../../utils/socket/socketConfig";
  import InfiniteScroll from "../InfiniteScroll.svelte";
  import {
    getAuthKey,
    getNonAuthConversationId,
    getPersonId,
    getPersonOrgOfficeId,
    removeNonAuthConversationId,
    setNonAuthConversationId,
  } from "../../utils/cookie/user";
  import {
    EndSessionCountApi,
    StartSessionCountApi,
  } from "../../utils/SessionCountApi";
  import { getRandomColor } from "../../utils/tools/data-transformers";
  import axios from "axios";
  import {
    BASIC_AI_URL,
    HELPDESK_MODULE,
    DOMAIN,
    FEEDBACK_MODULE,
  } from "../../config/api-variables";
  import MessageLoading from "./components/MessageLoading.svelte";
  import { menuItemsLoading, selectedMenuItem } from "../../stores/menuStores";
  import { resetChatSocket } from "../../utils/socket/socketManager";

  const dispatch = createEventDispatcher();

  let isFirstOpen = true;
  // let messages = [];
  // let messageLoading.set(true);;
  let disableMessageLoading = false;

  // let disableInput.set(false);
  // let ticketMainId = null;
  let textareaValue = "";
  let page = 1;
  let totalMessages = 0;
  let newBatch = [];

  let speedTestData = [];
  let selectedRating = 0;

  let scroll = 0;
  let isChatWidgetVisible = false;
  let isChatListVisible = false;
  let isSettingVisible = false;

  let isHelpTextVisible = false;
  let suggestionText = [];

  let selectedMessage = null;

  let chatContainer;

  let timeoutId;
  let requiredInputs = [];
  let aiDetails = {};

  let downloadSpeed;
  let uploadSpeed;

  let listOfUsers = [];
  let uniqueUserIds = [];

  let openSettingsCard = true;

  let conversationStatus = "open";

  // let callbackDisabled.set(true);

  let timeoutCount = 0;
  let secondTimeout;
  let busySystemErrorMessage = "";
  let delayErrorMessage = "";

  // let sessionTimeoutId;
  // let isSessionTimerActive;

  let selectedMenu;

  let dataQueue = []; // Queue to hold incoming data
  let processingQueue = false; // Flag to track if the queue is being processed

  let isTyping = false;

  let prevMessages;

  let thumbsDownOptions = [];

  $: if ($startNewAiConversation.startNew) {
    selectedMenuItem.set({ id: null });
    startNewAiConversation.set({ startNew: false });
    removeNonAuthConversationId();
    startNewConversation();
  }

  $: if (!!$messages.length > 0) {
    prevMessages = true;
  }

  $: if ($selectedMenuItem?.id && isVisible && !$menuItemsLoading) {
    isChatWidgetVisible = true;
    messages.set([]);
    dataQueue = [];
    processingQueue = false;
    page = 1;
    if (getNonAuthConversationId() && access_by_id) {
      updateConversationOwnerFn();
    } else if (getNonAuthConversationId() && !access_by_id) {
      console.log("else if is triggered");
      continueOldConversation();
    } else {
      console.log("else is triggered");
      // fetchMessageList();
      firstAction();
    }
  }

  const firstAction = () => {
    if (prevMessages || isFirstOpen) {
      fetchMessageList();
      console.log("fetchMessageList");
      isFirstOpen = false;
    } else {
      console.log("reset every thing");
      resetChatSocket();
      // fetchMessageList();
      sendMenuSocket();
    }
  };

  $: {
    if ($hasUserSentMessage) {
      StartSessionCountApi();
    } else {
      EndSessionCountApi();
    }
  }

  $: if (processingQueue || isTyping || $aiMessageLoading) {
    messageLoading.set(true);
  } else {
    messageLoading.set(false);
  }

  const handleVisibilityChange = () => {
    if (document.hidden && !$isSessionTimerActive) {
      isSessionTimerActive.set(true);
      sessionTimeoutId.set(
        setTimeout(() => {
          hasUserSentMessage.set(false);
        }, 900000),
      );
    }
  };

  const GetTranslatedText = (subjectType) => {
    axios
      .post(BASIC_AI_URL + "/ai/translation/message", {
        person_id: getPersonId(),
        context_type: "ui",
        subject_type: subjectType,
      })
      .then((response) => {
        // Handle the response data
        if (!$messages.length) {
          messages.update((currentMessages) => [
            {
              content: response.data.rows[0].translated_message,
              media_type: "error_message",
            },
            ...currentMessages,
          ]);
        } else {
          messages.update((currentMessages) => [
            {
              content: response.data.rows[0].translated_message,
              person_id: aiDetails?.person_id,
              title: aiDetails?.title,
              person_avatar: aiDetails?.person_avatar,
              id: new Date().getTime(),
              created_at: new Date().getTime(),
            },
            ...currentMessages,
          ]);
        }

        if (subjectType === "busy_system") {
          busySystemErrorMessage = response.data.rows[0].translated_message;
        } else if (subjectType === "delay") {
          delayErrorMessage = response.data.rows[0].translated_message;
        }
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });
  };

  const cancelTimeout = () => {
    clearTimeout(timeoutId);
  };

  $: {
    if ($messageLoading === true && !$messages.length && isVisible) {
      cancelTimeout();
      timeoutId = setTimeout(() => {
        GetTranslatedText("busy_system");
        messageLoading.set(false);
        disableInput.set(true);
      }, 120000);
    } else if (messageLoading === true && $messages.length && isVisible) {
      clearTimeout(secondTimeout);
      secondTimeout = setTimeout(() => {
        if (timeoutCount === 0) {
          GetTranslatedText("delay");
        } else if (timeoutCount === 1) {
          GetTranslatedText("busy_system");
          messageLoading.set(false);
          disableInput.set(true);
        }
        timeoutCount++;
      }, 180000);
    } else {
      cancelTimeout();
      clearTimeout(secondTimeout);
      timeoutCount = 0;
    }
  }

  const scrollToBottom = (node) => {
    // const scroll = () => node.scroll({
    // 	bottom: node.scrollHeight,
    // 	behavior: 'smooth',
    // });
    // scroll();
    // return { update: scroll }
  };

  function scrollToTop() {
    if (chatContainer) {
      chatContainer.scrollTop = 0; // Set scrollTop to 0 to scroll to the top
      // chatContainer.scrollTo({
      //   top: 0,
      //   behavior: 'smooth'
      // }) // Set scrollTop to 0 to scroll to the top
    }
  }

  const sendSocketData = (value) => {
    $chatSocket.emit("chat_ai_ticket_message_v2", {
      app_type: "CUSTOMER",
      organisation_office_id: getPersonOrgOfficeId(),
      constituency_id: 1,
      ticket_main_id: value?.ticketMainId,
      person_id: getPersonId(),
      content: value?.content,
      is_media_available: null,
      is_location_available: null,
      latitude: null,
      longitude: null,
      locality: null,
      address: null,
      category_id: null,
      required_inputs: null,
      ticket_id: null,
      menu_id: value?.menu_id,
      parent_menu_id: value?.parent_menu_id,
      menu_request_type: value?.menu_request_type,
      required_input_data: value?.required_input_data,
    });
  };

  const updateConversationOwnerFn = () => {
    updateConversationOwner(parseInt(getNonAuthConversationId()));
    isChatWidgetVisible = true;
    messages.set([]);
    messageLoading.set(false);
    page = 1;
    ticketMainId.set(parseInt(getNonAuthConversationId()));
    suggestionText = [];
    selectedRating = 0;
    isHelpTextVisible = false;
    removeNonAuthConversationId();
    markAsUnread(parseInt(getNonAuthConversationId()));
  };

  const continueOldConversation = () => {
    isChatWidgetVisible = true;
    messages.set([]);
    messageLoading.set(false);
    processingQueue = false;
    page = 1;
    ticketMainId.set(parseInt(getNonAuthConversationId()));
    suggestionText = [];
    selectedRating = 0;
    isHelpTextVisible = false;
    fetchMessageList();
    // removeNonAuthConversationId();
    $chatSocket.emit("join_ticket", $ticketMainId);
    markAsUnread(parseInt(getNonAuthConversationId()));
  };

  function processQueue() {
    if (dataQueue.length > 0) {
      processingQueue = true;
      isTyping = true;
      const data = dataQueue.shift();
      // messages = [data, ...messages];
      messages.update((currentMessages) => [data, ...currentMessages]);
    } else {
      processingQueue = false;
    }
  }

  function handleTypingFinished() {
    isTyping = false;
    processQueue();
    // fetchSuggestionsList();
  }

  function setupSocketListeners() {
    console.log("chatSocket reactive statment triggered");
    $chatSocket.emit("join", getPersonId());
    $chatSocket.on("connect", () => {
      console.log($chatSocket.connected);
    });
    $chatSocket.on("chat_ai_ticket_message_v2", (data) => {
      console.log(data, "data");
      if ($messages.length === 0 && !$ticketMainId) {
        if ($oldTicketMainId !== data.ticket_main_id) {
          ticketMainId.set(data.ticket_main_id);
        }

        // added condition so that it does not add the same message again
        if (
          !dataQueue.map((item) => item.id).includes(data.id) &&
          !$messages.map((item) => item.id).includes(data.id) &&
          $oldTicketMainId !== data.ticket_main_id &&
          data.menu_id === $selectedMenuItem?.id
        ) {
          dataQueue.push(data);
          if (!processingQueue) {
            processQueue();
          }
        }
        // dataQueue.push(data);
        // if (!processingQueue) {
        //   processQueue();
        // }
        updateUnreadCountSocket(data.id, data.ticket_main_id);
        // fetchSuggestionsList();
        // if (typeof data.is_input_text_disable === "undefined") {
        //   messageLoading.set(false);
        // } else {
        //   messageLoading.set(data?.is_input_text_disable);
        // }
      } else {
        // if (messages.length !== 1 && ticketMainId == data.ticket_main_id) {
        if ($ticketMainId == data.ticket_main_id) {
          if (data.person_id !== parseInt(getPersonId())) {
            aiDetails = {
              title: data.title,
              person_avatar: data.person_avatar,
              person_id: data.person_id,
            };
            if (typeof data.is_input_text_disable === "undefined") {
              aiMessageLoading.set(false);
            } else {
              aiMessageLoading.set(data?.is_input_text_disable);
            }
            // if (data?.required_inputs) {
            //   if (data?.required_inputs.includes("speedtest")) {
            //     // speedTestStart();
            //     downloadSpeedTestStart();
            //   } else if (data?.required_inputs.includes("rating")) {
            //     messageLoading.set(false);
            //     disableInput.set(true);
            //   } else if (data?.required_inputs.includes("callback")) {
            //     messageLoading.set(false);
            //     callbackDisabled.set(true);
            //   }
            // }
            scroll++;
            scrollToTop();
            // messages = [data, ...messages];
            // dataQueue.push(data);
            // if (!processingQueue) {
            //   processQueue();
            // }

            // added condition so that it does not add the same message again
            if (
              !dataQueue.map((item) => item.id).includes(data.id) &&
              !$messages.map((item) => item.id).includes(data.id) &&
              $oldTicketMainId !== data.ticket_main_id &&
              data.menu_id === $selectedMenuItem?.id
            ) {
              dataQueue.push(data);
              if (!processingQueue) {
                processQueue();
              }
            }
            if (!access_by_id && !getNonAuthConversationId()) {
              setNonAuthConversationId(data.ticket_main_id);
            }
            isHelpTextVisible = false;
            suggestionText = [];
            updateUnreadCountSocket(data.id, data.ticket_main_id);
            if ($messageLoading === false) {
              // fetchSuggestionsList();
            }
          }
        }
      }
      if ($messages.length === 1) {
        $chatSocket.emit("join_ticket", $ticketMainId);
        aiDetails = {
          title: data.title,
          person_avatar: data.person_avatar,
          person_id: data.person_id,
        };
        listOfUsers = [
          {
            person_id: data.person_id,
            avatar_color: getRandomColor(),
            title: data.title,
          },
          {
            person_id: parseInt(getPersonId()),
            avatar_color: getRandomColor(),
            title: $userDetails?.first_name,
          },
        ];
      }

      // if (typeof data.is_input_text_disable === "undefined") {
      //   aiMessageLoading.set(false);
      //   // messageLoading.set(false);
      // } else {
      //   aiMessageLoading.set(data?.is_input_text_disable);
      //   // messageLoading.set(data?.is_input_text_disable);
      // }

      if (data?.required_inputs) {
        if (data?.required_inputs.includes("speedtest")) {
          // speedTestStart();
          downloadSpeedTestStart();
        } else if (data?.required_inputs.includes("rating")) {
          messageLoading.set(false);
          disableInput.set(true);
        } else if (data?.required_inputs.includes("callback")) {
          // messageLoading.set(false);
          callbackDisabled.set(false);
        }
      }
      let findUsers = listOfUsers.find(
        (user) => user.person_id === data.person_id,
      );
      if (!findUsers) {
        listOfUsers = [
          ...listOfUsers,
          {
            person_id: data.person_id,
            avatar_color: getRandomColor(),
            title: data.title,
          },
        ];
      }
    });
    $chatSocket.on("update_conversation_owner", (data) => {
      fetchMessageList();
    });
  }

  $: if ($isSocketReset) {
    setupSocketListeners();
  }

  onMount(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);

    fetchThumbsDownOption();
  });

  const downloadSpeedTestStart = () => {
    const clientStartTime = new Date().getTime();
    const xhr = new XMLHttpRequest();
    xhr.open("GET", `${BASIC_AI_URL}/ai/user/speed/download`, true);
    xhr.responseType = "arraybuffer";

    xhr.onload = function () {
      const clientEndTime = new Date().getTime();
      const downloadData = xhr.response;
      const downloadDataSizeMbit =
        (downloadData.byteLength * 8) / (1024 * 1024);
      const clientDownloadTime = (clientEndTime - clientStartTime) / 1000;
      const downloadSpeedMbps = downloadDataSizeMbit / clientDownloadTime;

      downloadSpeed = downloadSpeedMbps.toFixed(2);

      uploadSpeedTestStart();
    };

    xhr.onerror = function (error) {
      console.error("Download Test Error:", error);
    };

    xhr.send();
  };

  const uploadSpeedTestStart = () => {
    const clientStartTime = new Date().getTime();
    const testFile = new Uint8Array(10 * 1024 * 1024).fill(0);

    const formData = new FormData();
    formData.append("file", new Blob([testFile]), "test_file.txt");

    const xhr = new XMLHttpRequest();
    xhr.open("POST", `${BASIC_AI_URL}/ai/user/speed/upload`, true);

    xhr.onload = function () {
      const clientEndTime = new Date().getTime();
      const uploadSpeedInfo = JSON.parse(xhr.responseText).rows;

      const uploadSpeedMbps = uploadSpeedInfo.upload_speed;
      const clientUploadTime = (clientEndTime - clientStartTime) / 1000;
      const clientUploadSpeed =
        uploadSpeedInfo.upload_data_size_mbit / clientUploadTime;

      uploadSpeed = clientUploadSpeed.toFixed(2);
      sendSpeedTest();
    };

    xhr.onerror = function (error) {
      console.error("Upload Test Error:", error);
    };

    xhr.send(formData);
  };

  const fetchMessageList = () => {
    const headers = { Authorization: "Token " + getAuthKey() };

    const payload = {
      search_val: null,
      page_size: 10,
      page_number: page,
      ticket_main_id: parseInt($ticketMainId),
      applicable_to: "customer",
      menu_id: $selectedMenuItem?.id,
    };
    axios
      .post(
        HELPDESK_MODULE + "/ticket/comments/list",
        { ...payload },
        { headers },
      )
      .then((response) => {
        newBatch = response.data.rows.map((message) => ({
          ...message,
          isMessageOld: true,
        }));
        messages.update((currentMessages) => [...currentMessages, ...newBatch]);
        uniqueUserIds = [...new Set($messages.map((user) => user.person_id))];
        listOfUsers = uniqueUserIds.map((userId) => ({
          person_id: userId,
          avatar_color: getRandomColor(),
        }));
        totalMessages = response.data.total;
        if (page === 1 && !newBatch.length) {
          messageLoading.set(true);
          sendMenuSocket();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchThumbsDownOption = () => {
    const headers = { Authorization: "Token " + getAuthKey() };

    const payload = {
      organisation_office_id: getPersonOrgOfficeId(),
    };
    axios
      .post(
        FEEDBACK_MODULE + "/thumbs/down/options",
        { ...payload },
        { headers },
      )
      .then((response) => {
        if (response.data.statusCode === "S10001") {
          thumbsDownOptions = response.data.rows;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchSuggestionsList = () => {
    const payload = {
      person_id: getPersonId(),
      organisation_office_id: getPersonOrgOfficeId(),
      conversation_id: $ticketMainId,
      applicable_to: "customer",
      page_number: 1,
      page_size: 10,
    };
    if (!access_by_id) {
      payload.industry_category = "sales";
    }
    axios
      .post(BASIC_AI_URL + "/ai/user/message/suggestion", { ...payload })
      .then((response) => {
        suggestionText = response.data.rows;
        if (suggestionText.length !== 0) isHelpTextVisible = true;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleChatList = () => {
    isChatListVisible = !isChatListVisible;
  };

  const sendHelpText = (helpText) => {
    textareaValue = helpText;
    sendMessage();
  };

  const updateUnreadCountSocket = (comment_id, ticket_id) => {
    $chatSocket.emit("update_ticket_comment_status", {
      ticket_comment_id: comment_id,
      ticket_main_id: ticket_id,
      comment_status: "read",
    });
  };

  const markAsUnread = (ticketId) => {
    $chatSocket.emit("mark_conversation", {
      ticket_main_id: ticketId,
      status: "unread",
    });
  };

  const updateConversationOwner = (ticket_id) => {
    $chatSocket.emit("update_conversation_owner", {
      ticket_main_id: ticket_id,
    });
  };

  const openLink = (link) => {
    window.open(DOMAIN + link, "_blank");
  };

  $: if ($selectedConversation) {
    setSelectedMessage($selectedConversation);
    messages.set([]);
    isTyping = false;
    dataQueue = [];
    processingQueue = false;
  }

  const setSelectedMessage = (selectedItem) => {
    selectedMessage = selectedItem;
    updateUnreadCountSocket(selectedItem.last_comment.id, selectedItem.id);
    isChatWidgetVisible = true;
    messages.set([]);
    messageLoading.set(false);
    hasUserSentMessage.set(false);
    selectedMenuItem.set({ id: null });
    page = 1;
    oldTicketMainId.set(null);
    ticketMainId.set(selectedItem.id);
    fetchMessageList();
    suggestionText = [];
    selectedRating = 0;
    isHelpTextVisible = false;
    if (selectedItem.conversation_status === "open") {
      fetchSuggestionsList();
    }
    if (selectedItem.is_picked === true) {
      disableMessageLoading = true;
      messageLoading.set(false);
    }
    $chatSocket.emit("join_ticket", $ticketMainId);
    conversationStatus = selectedItem.conversation_status;
    if ($DeviceType === "mobile") {
      isChatListVisible = false;
      isChatWidgetVisible = true;
    }
  };

  function handleKeyDown(event) {
    // Stop keyboard event propagation
    event.stopPropagation();
  }

  // socket for sending data
  const sendMessage = () => {
    if (textareaValue.trim() !== "") {
      const value = {
        ticketMainId: $ticketMainId,
        content: textareaValue,
      };
      sendSocketData(value);
      textareaValue = "";
      // hasUserSentMessage = true;
      disableInput.set(true);
      requiredInputs = [];
    }
    scroll++;
    messages.update((currentMessages) => [
      {
        content: textareaValue,
        person_id: parseInt(getPersonId()),
        title: $userDetails?.first_name + " " + $userDetails?.last_name,
        person_avatar: $userDetails?.profile_image,
        id: new Date().getTime(),
        created_at: new Date().getTime(),
      },
      ...currentMessages,
    ]);
    textareaValue = "";
    if (!disableMessageLoading) {
      messageLoading.set(true);
    }
    // messageLoading.set(true);;
    isHelpTextVisible = false;
    suggestionText = [];
    PlaySound("https://testcdn.cleandesk.co.in/sounds/send-message-sound.mp3");
    scrollToTop();
  };

  const sendSpeedTest = () => {
    const value = {
      ticketMainId: $ticketMainId,
      menu_id: $selectedMenuItem?.id,
      required_input_data: {
        download_speed: downloadSpeed,
        upload_speed: uploadSpeed,
      },
    };
    sendSocketData(value);
  };

  function sendFeedbackData(event) {
    const data = event.detail;
    selectedRating = data.selectedRating;

    const value = {
      ticketMainId: $ticketMainId,
      menu_id: $selectedMenuItem?.id,
      required_input_data: {
        rating: data.selectedRating,
        comment_text: data.commentValue,
        text: data.ratingTitleText,
        action_text: data.actionText,
      },
    };
    sendSocketData(value);
    PlaySound("https://testcdn.cleandesk.co.in/sounds/send-message-sound.mp3");
    messageLoading.set(true);
  }

  const sendMenuSocket = () => {
    const value = {
      ticketMainId: $ticketMainId,
      menu_id: $selectedMenuItem?.id,
      parent_menu_id: $selectedMenuItem?.parent_menu_id,
      menu_request_type: "intro",
    };
    sendSocketData(value);
  };

  const dispatchAskMore = (text) => {
    dispatch("followOnUserInput", text.detail);
  };

  const startNewConversation = () => {
    resetChatSocket();
    messageLoading.set(true);
    oldTicketMainId.set($ticketMainId);
    ticketMainId.set(null);
    dataQueue = [];
    processingQueue = false;
    isTyping = false;
    selectedConversation.set(null);
    messages.set([]);
    page = 1;
    hasUserSentMessage.set(false);
    selectedMessage = null;
    selectedRating = 0;
    isHelpTextVisible = false;
    timeoutCount = 0;
    conversationStatus = "open";
    callbackDisabled.set(true);
    sendSocketData();
  };
</script>

<div class="cleandesk-chat-body">
  <div
    use:scrollToBottom={$messages}
    class="cleandesk-conversation-container"
    style="display: flex; overflow-y: scroll; flex-direction: column-reverse; {$widgetType ===
      'chatsite' && 'padding: 0 5%;'}"
    bind:this={chatContainer}
  >
    <InfiniteScroll
      hasMore={newBatch.length}
      reverse={true}
      threshold={100}
      on:loadMore={() => {
        page++;
        fetchMessageList();
      }}
    />

    {#if $messageLoading === true && isTyping === false}
      <MessageLoading {aiDetails} />
      <!-- <div class="message-item-container">
        <div style="width: 32px;">
          {#if !!aiDetails.title}
            <div
              class="chat-header-avatar"
              style="display: flex; align-items: center;"
            >
              <img
                src={DOMAIN + aiDetails?.person_avatar}
                alt="a"
                style="height: 24px; border-radius: 50%; margin-right: 8px;"
              />
            </div>
          {/if}
        </div>
        <div style="width: 100%;">
          {#if !!aiDetails.title}
            <div
              class="chat-header-avatar"
              style="display: flex; align-items: center;"
            >
              <div
                class="header-profile-name"
                style="font-size: 16px; font-weight: 600;"
              >
                {aiDetails?.title.toLowerCase()}
              </div>
            </div>
          {/if}
          <div
            style="padding: 16px; margin: 0 0 0 -15px; width: 50px; border-radius: 8px"
          >
            <div class="spinner">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
            </div>
          </div>
        </div>
      </div> -->
    {/if}

    {#each $messages as message, index (message.id)}
      {#if message.media_type === "application/pdf"}
        <div
          style="background-color: #fff; margin: 10px; width: auto; border-radius: 8px; max-width: 80%;"
        >
          <button
            style="width: 100%; padding: 10px; border: none; border-radius: 8px; cursor: pointer; background: none"
            type="button"
            on:click={openLink(message.media_url)}
          >
            <p>Click here to open pdf</p>
          </button>
        </div>
      {:else if message.media_type === "error_message"}
        <div class="message-item-container">
          <div class="message-item-body" style="padding: 8px;">
            <p>
              {@html message?.content}
            </p>
          </div>
        </div>
      {/if}

      {#if !!message.person_id === true}
        {#if message.content_type === "json"}
          <MessageJson
            messageJson={message}
            on:feedbackData={sendFeedbackData}
            {selectedRating}
          />
        {:else if message.content_type === "html" && message.object_type === "product"}
          <div style="margin: 4px 10px;">
            {@html message?.content}
          </div>
        {:else}
          <MessageItem
            {message}
            {listOfUsers}
            {thumbsDownOptions}
            isMine={message?.person_id === parseInt(getPersonId())}
            prevSame={$messages[index + 1]?.person_id === message?.person_id}
            isLastMessage={index === 0}
            on:typingFinished={handleTypingFinished}
            on:followOnUserInput={dispatchAskMore}
          />
        {/if}
      {/if}
    {/each}
  </div>
</div>
